<template>
  <div class="container">
    <div class="heading" v-if="showTitle">
      <div class="utama space-y-[5px]">
        <h1>
          {{ title }}
          <span v-if="insertTitle">{{ insertTitle }}</span>
        </h1>
        <p>
          {{ subtitle }}
        </p>
      </div>

      <div class="addition">
        <div></div>
        <p>
          {{ label }}
        </p>
      </div>
    </div>
    <canvas ref="chart" />
  </div>
</template>

<script>
import Chart from "chart.js";
import moment from "moment";

export default {
  props: {
    id: {
      type: String,
      default: "1",
    },

    title: {
      type: String,
      default: "",
    },

    insertTitle: {
      type: String,
      default: "",
    },

    subtitle: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    data: {
      type: Object,
      default: () => {
        return {};
      },
    },

    options: {
      type: Object,
      default: () => {
        return {
          tooltips: {
            callbacks: {
              title: (val) => {
                return moment(val[0].xLabel).format("D MMM YYYY");
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },

          legend: {
            display: false,
          },

          scales: {
            xAxes: [
              {
                type: "linear",
                position: "bottom",
                ticks: {
                  callback: (value) => moment(value).format("MMM"),
                  padding: 10,
                },
              },
            ],
          },
        };
      },
    },

    showTitle: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      chart: null,
    };
  },
  methods: {
    createChart() {
      console.log('data', this.data)
      const ctx = this.$refs.chart.getContext("2d");
      if (!this.chart && ctx) {
        this.chart = new Chart(ctx, {
          type: "line",
          data: this.data,
          options: this.options,
        });
      }
    },
    removeChart(removeToo = false) {
      if (this.chart) {
        this.chart.destroy();
        if (removeToo) this.chart = null;
      }
    },
  },
  unmounted() {
    this.removeChart();
  },
  mounted() {
    this.createChart();
  },
  watch: {
    data: {
      handler() {
        this.removeChart(true);
        this.createChart();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    div.utama {
      gap: 5px;

      h1 {
        font-weight: bold;
        font-size: 18px;
        line-height: 140%;
        color: black;

        span {
          font-weight: normal;
          font-size: 14px;
          line-height: 150%;
          color: #6b7280;
        }
      }

      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        color: #6b7280;
      }
    }

    div.addition {
      gap: 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      div {
        height: 2px;
        width: 50px;
        background-color: black;
      }

      p {
        font-weight: 500;
        text-align: right;
        font-size: 12px;
        line-height: 20px;
        color: #6b7280;
        margin: 0;
      }
    }
  }

  .product-name {
    margin-top: 20px;

    h3 {
      font-weight: bold;
      font-size: 16px;
      color: #333;
    }
  }

  canvas {
    width: 100%;
    margin-top: 39px;
    height: 350px;
  }
}
</style>