<template>
  <div class="compare-container">
    <Header></Header>
    <HeaderBottom></HeaderBottom>
    <b-container class="compare-container-content">
      <!-- Bagian 1 -->
      <div class="
          content-1
          2xl:w-[79.16%] 2xl:mx-[auto]
          lg:px-[32px] lg:py-[50px]
          space-y-[40px]
          w-100
          d-flex
          flex-column
          justify-content-center
          align-items-center
        ">
        <div class="w-100 d-flex align-items-center justify-content-start">
          <p class="heading">Perbandingan Product Obligasi</p>
        </div>

        <!-- Table Bootstrap -->
        <div class="container-table-parent" v-if="statusRequest === 'error'">
          <div class="message">
            <h1>Gagal mengambil data :(</h1>
          </div>
        </div>
        <div class="container-table-parent" v-else>
          <b-skeleton-table v-if="statusRequest === 'loading'" :rows="5" :columns="9"
            :table-props="{ hover: true, responsive: true }"></b-skeleton-table>
          <b-table v-else id="my-table" :hover="true" :responsive="true" :items="dataTable" :fields="table.fields"
            :per-page="table.perPage" :current-page="table.currentPage" :show-empty="true">
            <template #cell(title)="data">
              <div>
                <div>
                  <input type="checkbox" placeholder="checked" name="checked" :value="data.value.id"
                    v-model="listNavCheckbox" />
                  <div></div>
                </div>
                <div>
                  <p class="textCommon">{{ data.value.name }}</p>
                  <!-- <p>{{ data.value.im_name }}</p> -->
                </div>
              </div>
            </template>
            <template #cell()="data">
              <p class="textCommon">{{ data.value }}</p>
            </template>
            <template #cell(redirect)="data">
              <b-button :class="{ 'btn-tertiary': true, disabled: data.item.disabled }"
                @click="redirectButton(data.item.redirect, data.item.disabled)">
                Beli
              </b-button>
            </template>
          </b-table>

          <b-pagination v-if="statusRequest !== 'loading'" v-model="table.currentPage" :total-rows="tableTotalRows"
            :per-page="table.perPage" aria-controls="my-table"></b-pagination>
        </div>

        <!-- Button -->
        <div class="container-button">
          <!-- <button @click="listNavCheckbox = []">Hapus Semua</button> -->
          <button @click="filterButton">Bandingkan</button>
        </div>
      </div>
    </b-container>

    <b-container v-if="showChart && (!!chart1Config || !!chart2Config)">
      <b-tabs active-nav-item-class="tabsActive" class="tabs-container">
        <b-tab active title-item-class="itemsTab">
          <template #title>
            Harga
          </template>
          <div>
            <!-- Bagian 2 -->
            <div class="content-2">
              <div class="heading">
                <p>Perbandingan Price</p>
              </div>

              <!-- Body Graph -->
              <div class="content-2-content">
                <div class="nav-item">
                  <div class="nav-item-content">
                    <div v-if="chart1Status === 'error'" class="content-loading">
                      <p>Gagal mengambil data ke server</p>
                    </div>
                    <!-- Content Loading -->
                    <div v-else-if="chart1Status === 'loading'" class="content-loading">
                      <b-spinner label="Spinning"></b-spinner>
                    </div>
                    <!-- Content -->
                    <div v-else class="content">
                      <!-- Content Body -->
                      <div class="w-100 h-100">
                        <Chart v-if="chart1Config" title="Performa" :subtitle="`Per ${dateNow}`" label="Harga"
                          :data="chart1Config" :options="chartSetting" />
                      </div>
                    </div>
                    <!-- Button Action -->
                    <div class="action">
                      <button @click="chartFilter = '1M'" :disabled="chart1Status === 'loading'"
                        :class="{ active: chartFilter === '1M' }">
                        1M
                      </button>
                      <button @click="chartFilter = '3M'" :disabled="chart1Status === 'loading'"
                        :class="{ active: chartFilter === '3M' }">
                        3M
                      </button>
                      <button @click="chartFilter = '6M'" :disabled="chart1Status === 'loading'"
                        :class="{ active: chartFilter === '6M' }">
                        6M
                      </button>
                      <button @click="chartFilter = 'YTD'" :disabled="chart1Status === 'loading'"
                        :class="{ active: chartFilter === 'YTD' }">
                        YTD
                      </button>
                      <button @click="chartFilter = '1Y'" :disabled="chart1Status === 'loading'"
                        :class="{ active: chartFilter === '1Y' }">
                        1Y
                      </button>
                      <button @click="chartFilter = '3Y'" :disabled="chart1Status === 'loading'"
                        :class="{ active: chartFilter === '3Y' }">
                        3Y
                      </button>
                      <button @click="chartFilter = '5Y'" :disabled="chart1Status === 'loading'"
                        :class="{ active: chartFilter === '5Y' }">
                        5Y
                      </button>
                      <!-- <button
                    @click="chartFilter = '10Y'"
                    :disabled="chart1Status === 'loading'"
                    :class="{ active: chartFilter === '10Y' }"
                  >
                    10Y
                  </button> -->
                      <!-- <button
                    @click="chartFilter = 'ALL'"
                    :disabled="chart1Status === 'loading'"
                    :class="{ active: chartFilter === 'ALL' }"
                  >
                    ALL
                  </button> -->
                    </div>
                  </div>
                </div>
                <div v-if="chart2Config" class="nav-item">
                  <div class="nav-item-content">
                    <div v-if="chart2Status === 'error'" class="content-loading">
                      <p>Gagal mengambil data ke server</p>
                    </div>
                    <!-- Content Loading -->
                    <div v-else-if="chart2Status === 'loading'" class="content-loading">
                      <b-spinner label="Spinning"></b-spinner>
                    </div>
                    <!-- Content -->
                    <div v-else class="content">
                      <!-- Content Body -->
                      <div class="w-100 h-100">
                        <Chart v-if="chart2Config" title="Pertumbuhan Dana Kelolaan" insertTitle="(Dalam Satuan Miliar)"
                          :subtitle="`Per ${dateNow}`" label="Number Of AUM" :data="chart2Config"
                          :options="chart2Setting" />
                      </div>
                    </div>
                    <!-- Button Action -->
                    <div class="action">
                      <button @click="chart2Filter = '1D'" :disabled="true" :class="{ active: chart2Filter === '1D' }">
                        1D
                      </button>
                      <button @click="chart2Filter = '1M'" :disabled="true" :class="{ active: chart2Filter === '1M' }">
                        1M
                      </button>
                      <button @click="chart2Filter = '3M'" :disabled="true" :class="{ active: chart2Filter === '3M' }">
                        3M
                      </button>
                      <button @click="chart2Filter = 'YTD'" :disabled="true" :class="{ active: chart2Filter === 'YTD' }">
                        YTD
                      </button>
                      <button @click="chart2Filter = '1Y'" :disabled="true" :class="{ active: chart2Filter === '1Y' }">
                        1Y
                      </button>
                      <button @click="chart2Filter = '3Y'" :disabled="true" :class="{ active: chart2Filter === '3Y' }">
                        3Y
                      </button>
                      <button @click="chart2Filter = '5Y'" :class="{ active: chart2Filter === '5Y' }">
                        5Y
                      </button>
                      <!-- <button
                    @click="chart2Filter = '10Y'"
                    :class="{ active: chart2Filter === '10Y' }"
                  >
                    10Y
                  </button> -->
                      <!-- <button
                    @click="chart2Filter = 'ALL'"
                    :disabled="true"
                    :class="{ active: chart2Filter === 'ALL' }"
                  >
                    ALL
                  </button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <!-- <b-tab disabled title-item-class="itemsTab">
          <template #title>
            Yield
          </template>
        </b-tab> -->
      </b-tabs>
    </b-container>

    <!-- Syariah Prevent -->
    <b-modal v-model="modalSyariah" size="md" class="modal-failed-register" centered hide-footer hide-header
      no-close-on-backdrop>
      <div class="d-block text-center">
        <!-- <img
                src="@/assets/img/ekyc/verification-proccess.svg"
                alt="data-not-correct"
              /> -->
        <h1 class="p-4 my-2 text-dark">Tidak Diijinkan</h1>
        <p>Anda terdaftar sebagai nasabah Syariah</p>
        <b-row class="justify-content-md-end btn-submit-row mb-4">
          <div class="col-lg-12 col-md-6">
            <b-button size="sm" class="btn-tertiary px-4 py-2" @click="modalSyariah = false">Tutup</b-button>
          </div>
        </b-row>
      </div>
    </b-modal>


    <Footer />
  </div>
</template>

<script>
import Header from "@/components/partials/header/Header.vue";
import HeaderBottom from "@/components/partials/header/HeaderBottom.vue";
import Footer from "@/components/partials/footer/Footer.vue";
import Chart from "@/components/partials/chart/index.vue";
import Axios from "axios";
import moment from "moment";
import { mapGetters } from 'vuex'
const numeral = require("numeral");

const InstanceAxios = Axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
});

export default {
  name: "ObligasiCompare",
  metaInfo: {
    title: "Obligasi Compare | CGS iTrade Fund",
  },
  components: {
    Header,
    HeaderBottom,
    Footer,
    Chart,
  },
  data: function () {
    return {
      // integrasi
      statusRequest: "iddle",
      listNav: null,
      listNavCheckbox: [],
      filterPeriode: "1Y",
      filterDateFrom: "",
      filterDateAfter: "",
      chartFilter: "1Y",
      chart2Filter: "5Y",
      chart1Config: null,
      chart2Config: null,
      chart1Status: "iddle",
      chart2Status: "iddle",
      showChart: false,

      // Table
      table: {
        fields: [
          {
            key: "title",
            label: "Obligasi",
            sortable: true,
          },
          {
            key: "kodeObligasi",
            label: "Kode Obligasi",
          },
          // {
          //   key: "barometer",
          //   label: "Barometer (1Th)",
          // },
          {
            key: "jenis",
            label: "Jenis",
          },
          {
            key: "harga",
            label: "Harga",
          },
          {
            key: "offerPrice",
            label: "Offer Price",
          },
          {
            key: "bidPrice",
            label: "Bid Price",
          },
          {
            key: "maturity",
            label: "Maturity Date",
          },
          {
            key: "kupon",
            label: "Kupon",
          },
          {
            key: "redirect",
            label: "Beli",
          },
        ],

        currentPage: 1,
        perPage: 10,
      },

      // Modal
      modalSyariah: false
    };
  },
  methods: {
    getBorderColor(value, background = false) {
      if (value % 3 === 0) {
        return !background ? "#EE503F" : "rgba(238, 80, 63, 0.2)";
      } else if (value % 2 === 0) {
        return !background ? "#00B227" : "#00963C33";
      } else {
        return !background ? "#0B318F" : "#0038FF33";
      }
    },
    getInisialLogo(title) {
      function getFirstLetter(letter) {
        return letter.split("")[0];
      }
      const titleSplit = title.split(" ");

      // Jika hanya dapat satu bagian
      if (titleSplit.length === 1)
        return `${getFirstLetter(titleSplit[0])}${getFirstLetter(
          titleSplit[0]
        )}`;

      return titleSplit.reduce((prevVal, val, index) => {
        if (prevVal.length === 2) return prevVal;
        if (index === titleSplit.length - 1 && prevVal.length === 1)
          return `${prevVal}A`;
        if (getFirstLetter(val).search(/^[A-Za-z]+$/) !== -1)
          return `${prevVal}${getFirstLetter(val).toUpperCase()}`;
        return prevVal;
      }, "");
    },
    formatNumber(value, dec) {
      let val = (value / 1).toFixed(dec).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    filterButton() {
      // this.chartFilter = this.filterPeriode;
      // this.chart2Filter = this.filterPeriode;
      if (this.listNavCheckbox.length > 0) {
        this.showChart = true;
        this.chartAction();
        // this.chart2Action();
      } else {
        alert("HARAP MEMILIH DATA TERLEBIH DAHULU");
      }
    },
    chartAction() {
      // this.chart1Config = {
      //   datasets: [
      //     {
      //       label: 'test',
      //       backgroundColor: this.getBorderColor(0, true),
      //       borderColor: this.getBorderColor(0),
      //       borderWidth: 2,
      //       tension: 0.5,
      //       pointStyle: "circle",
      //       pointRadius: this.chartFilter === "ALL" ? 3 : 0,
      //       pointHitRadius: 3,
      //       pointBorderWidth: 3,
      //       pointHoverBorderColor: this.getBorderColor(0, true),
      //       pointHoverBackgroundColor: this.getBorderColor(0),
      //       pointHoverBorderWidth: 1,
      //       pointHoverRadius: 6,
      //       fill: true,
      //       data: [
      //         {
      //           "bond_id": "FR0084",
      //           "date": "2022-12-29",
      //           "closing_price": "106.00"
      //         },
      //         {
      //           "bond_id": "FR0084",
      //           "date": "2022-12-30",
      //           "closing_price": "106.20"
      //         },
      //         {
      //           "bond_id": "FR0084",
      //           "date": "2022-12-31",
      //           "closing_price": "106.50"
      //         }
      //       ].map(candidate => ({
      //         x: new Date(candidate.date),
      //         y: parseInt(candidate.closing_price
      //         )
      //       })),
      //     }
      //   ],
      // };
      this.chart1Status = "loading";

      const listRequest = this.listNavCheckbox.map((candidate) => {
        let url = "";

        switch (this.chartFilter) {
          case "1M": {
            url = `api/fbonds/product/price-history?history_type=one_month&bond_id=${candidate}`;
            break;
          }
          case "3M": {
            url = `api/fbonds/product/price-history?history_type=three_month&bond_id=${candidate}`;
            break;
          }
          case "6M": {
            url = `api/fbonds/product/price-history?history_type=six_month&bond_id=${candidate}`;
            break;
          }
          case "YTD": {
            url = `api/fbonds/product/price-history?history_type=ytd&bond_id=${candidate}`;
            break;
          }
          case "1Y": {
            url = `api/fbonds/product/price-history?history_type=one_year&bond_id=${candidate}`;
            break;
          }
          case "3Y": {
            url = `api/fbonds/product/price-history?history_type=three_year&bond_id=${candidate}`;
            break;
          }
          case "5Y": {
            url = `api/fbonds/product/price-history?history_type=five_year&bond_id=${candidate}`;
            break;
          }
        }

        return InstanceAxios.get(url);
      });

      Promise.all(listRequest)
        .then((result) => {
          this.chart1Status = "iddle";
          this.chart1Config = {
            datasets: result.map((value, index) => {
              let label = "Tidak diketahui";

              if (
                typeof value.data[0] === "object" &&
                value.data[0] &&
                !Array.isArray(value.data[0])
              ) {
                switch (this.chartFilter) {
                  case "ALL": {
                    if (value.data[0]?.im_name) label = value.data[0]?.im_name;
                    break;
                  }

                  default: {
                    const find = this.listNav.find(
                      (candidateItem) =>
                        value.data[0]?.product_id === candidateItem.id
                    );

                    if (find && find?.im_name) {
                      label = find?.im_name;
                    }
                  }
                }
              }
              return {
                borderWidth: 1,
                // tension: 0.5,
                // pointRadius: this.chartFilter === "ALL" ? 3 : 0,
                // pointHitRadius: 3,
                // pointBorderWidth: 3,
                // pointHoverBorderWidth: 1,
                // pointHoverRadius: 6,
                label,
                backgroundColor: this.getBorderColor(index, true),
                borderColor: this.getBorderColor(index),
                pointStyle: "circle",
                pointHoverBorderColor: this.getBorderColor(index, true),
                pointHoverBackgroundColor: this.getBorderColor(index),
                fill: true,
                data: Array.isArray(value?.data?.data) ? value?.data?.data
                  .filter((candidate) => {
                    console.log(candidate)
                    return true;
                    // if (
                    //   this.filterDateAfter === "" ||
                    //   this.filterDateFrom === ""
                    // )
                    //   return true;
                    // const sebelum = moment(this.filterDateFrom).subtract(
                    //   1,
                    //   "days"
                    // );
                    // const sampai = moment(this.filterDateAfter).add(1, "days");
                    // return (
                    //   moment(candidate.date).isAfter(sebelum) &&
                    //   moment(candidate.date).isBefore(sampai)
                    // );
                  })
                  .sort((a, b) => new Date(a.date) - new Date(b.date))
                  .map((candidate) => ({
                    x: new Date(candidate.date).getTime(),
                    y: parseFloat(candidate.closing_price || 0),
                  })) : [],
              };
            }),
          };
        })
        .catch((err) => {
          console.log(err);
          this.chart1Config = "error";
        });
    },
    // chart2Action() {
    //   const listRequest = this.listNavCheckbox.map((candidate) => {
    //     this.chart2Status = "loading";
    //     return InstanceAxios.get(`api/aum-history/five-year?id=${candidate}`);
    //   });

    //   Promise.all(listRequest)
    //     .then((result) => {
    //       this.chart2Status = "iddle";
    //       this.chart2Config = {
    //         datasets: result.map(({ data }, index) => {
    //           let label = "Tidak diketahui";

    //           if (
    //             typeof data[0] === "object" &&
    //             data[0] &&
    //             !Array.isArray(data[0])
    //           ) {
    //             const find = this.listNav.find(
    //               (candidateItem) => data[0]?.product_id === candidateItem.id
    //             );

    //             if (find && find?.im_name) {
    //               label = find?.im_name;
    //             }
    //           }
    //           return {
    //             label,
    //             backgroundColor: this.getBorderColor(index, true),
    //             borderColor: this.getBorderColor(index),
    //             borderWidth: 2,
    //             tension: 0.5,
    //             pointStyle: "circle",
    //             pointRadius: 0,
    //             pointHitRadius: 3,
    //             pointBorderWidth: 3,
    //             pointHoverBorderColor: this.getBorderColor(index, true),
    //             pointHoverBackgroundColor: this.getBorderColor(index),
    //             pointHoverBorderWidth: 1,
    //             pointHoverRadius: 6,
    //             fill: true,
    //             data: data
    //               .filter((candidate) => {
    //                 if (
    //                   this.filterDateAfter === "" ||
    //                   this.filterDateFrom === ""
    //                 )
    //                   return true;
    //                 const sebelum = moment(this.filterDateFrom).subtract(
    //                   1,
    //                   "days"
    //                 );
    //                 const sampai = moment(this.filterDateAfter).add(1, "days");
    //                 return (
    //                   moment(candidate.aum_date).isAfter(sebelum) &&
    //                   moment(candidate.aum_date).isBefore(sampai)
    //                 );
    //               })
    //               .sort((a, b) => new Date(a.aum_date) - new Date(b.aum_date))
    //               .map((candidate) => ({
    //                 x: new Date(candidate.aum_date).getTime(),
    //                 y: parseFloat(candidate.aum || 0),
    //               })),
    //           };
    //         }),
    //       };
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.chart2Status = "error";
    //     });
    // },
    // chart2Action() {
    //   this.chart2Config = {
    //     datasets: this.listNav
    //       .filter((candidate) => this.listNavCheckbox.includes(candidate.id))
    //       .filter((candidate) => {
    //         if (this.filterDateAfter === "" || this.filterDateFrom === "")
    //           return true;
    //         const sebelum = moment(this.filterDateFrom).subtract(1, "days");
    //         const sampai = moment(this.filterDateAfter).add(1, "days");
    //         return (
    //           moment(candidate.date).isAfter(sebelum) &&
    //           moment(candidate.date).isBefore(sampai)
    //         );
    //       })
    //       .sort((a, b) => new Date(a) - new Date(b))
    //       .map((candidate, index) => ({
    //         label: candidate.im_name,
    //         backgroundColor: this.getBorderColor(index),
    //         borderColor: this.getBorderColor(index),
    //         borderWidth: 2,
    //         tension: 0.5,
    //         pointStyle: "circle",
    //         pointHoverBorderColor: "#FFFFFF",
    //         pointHoverBorderWidth: 1,
    //         pointRadius: 3,
    //         pointHoverRadius: 6,
    //         fill: true,
    //         data: [
    //           {
    //             x: new Date(candidate.created_at).getTime(),
    //             y: parseFloat(candidate.navAum || 0),
    //           },
    //         ],
    //       })),
    //   };
    // },
    formatNumeral(value, format) {
      return numeral(value).format(format);
    },
    redirectButton(url, disabled) {
      if (disabled) {
        this.modalSyariah = true;
      } else {
        this.$router.push(url)
      }
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['bg-primary', 'text-light']
      } else {
        return ['bg-light', 'text-info']
      }
    },
    formatDate (value) {
      return moment(value).format('DD MMM YYYY');
    },
    formatter(val) {
      return numeral(val).format('0,0.[0000]')
    },
    backFormatter(val) {
      return parseFloat(val.split('').filter(candidate => candidate !== ".").map(candidate => candidate.replace(',', '.')).join(''));
    },
    backTransaformFormatter(val) {
      if (!val) return;
      return val.toString().split('').map(val => val.replace('.', ',')).join('');
    }
  },
  mounted() {
    async function requestData() {
      try {
        this.statusRequest = "loading";

        // Request 2
        const request = await InstanceAxios("api/fbonds/product?bond_status=1&bond_type=corporate");
        const request2 = await InstanceAxios("api/fbonds/product?bond_status=1&bond_type=government");
        const resultData = request?.data?.data;
        const resultData2 = request2?.data?.data;

        // Cek struktur
        // Cek struktur dari respon
        if (!resultData || !Array.isArray(resultData) || !resultData2 || !Array.isArray(resultData2)) {
          throw new Error("Struktur respond yang diterima tidak sesuai");
        }

        // this.listNav = resultData;
        this.listNav = [
          ...resultData,
          ...resultData2,
        ]
        this.statusRequest = "iddle";
      } catch (err) {
        this.statusRequest = "error";
        console.log("\n\n================================");
        console.log(err);
        alert("ERROR");
      }
    }

    requestData.call(this);

    // Get Query Params
    if (this.$route.query?.products) {
      const parseQuery = this.$route.query?.products.split(',');
      if (parseQuery.length > 0) {
        this.listNavCheckbox = parseQuery;
        this.filterButton();
      }
    }
  },
  watch: {
    chartFilter: {
      flush: "post",
      immediate: true,
      handler() {
        this.chartAction();
      },
    },
    listNavCheckbox: {
      flush: "post",
      immediate: true,
      handler(value) {
        // Saat di uncheck ( bernilai kosong maka close chart)
        if (value.length <= 0) {
          this.showChart = false;
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    userSyariah() {
      return this?.user?.individual?.client_id?.slice(0, 4)?.toLowerCase() === "rjkl" ? true : false;
    },
    dataTable() {
      if (!this.listNav) return null;

      return this.listNav.map((candidate) => {
        return {
          title: {
            name: candidate.bond_name,
            id: candidate.bond_id
          },
          // disabled: this.userSyariah && candidate.is_sharia !== 1,
          redirect: `/subscription-fbonds-detail/${candidate.bond_id}/1`,
          // barometer: 2,
          kodeObligasi: candidate.bond_id,
          jenis: parseInt(candidate.bond_type) == 1 ? "Government" : "Corporate",
          harga: this.formatter(this.backTransaformFormatter(candidate.buy_price)),
          offerPrice: this.formatter(this.backTransaformFormatter(candidate.sell_price)),
          bidPrice: this.formatter(this.backTransaformFormatter(candidate.buy_price)),
          maturity: this.formatDate(candidate.maturity_date),
          kupon: `${this.formatter(this.backTransaformFormatter(candidate.coupon_rate))}%`,
        };
      });
    },
    tableTotalRows() {
      return Array.isArray(this.dataTable) ? this.dataTable.length : 0;
    },
    chartSetting() {
      return {
        tooltips: {
          // enabled: false,
          callbacks: {
            title: (val) => {
              return moment(val[0].xLabel).format("D MMM YYYY");
            },
            label: (val) => this.formatNumeral(parseFloat(val.value), "0,0.0"),
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },

        legend: {
          display: false,
        },

        scales: {
          xAxes: [
            {
              type: "linear",
              position: "bottom",
              ticks: {
                // Include a dollar sign in the ticks
                callback: (value) => moment(value).format("DD MMM YYYY"),
                padding: 10,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                // Include a dollar sign in the ticks
                callback: (value) => this.formatNumeral(value, "0,0"),
                padding: 10,
              },
            },
          ],
        },
      };
    },
    chart2Setting() {
      return {
        tooltips: {
          // enabled: false,
          callbacks: {
            title: (val) => {
              return moment(val[0].xLabel).format("D MMM YYYY");
            },
            label: (val) => this.formatNumeral(parseFloat(val.value), "0,0.0"),
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },

        legend: {
          display: false,
        },

        scales: {
          xAxes: [
            {
              type: "linear",
              position: "bottom",
              ticks: {
                // Include a dollar sign in the ticks
                callback: (value) => moment(value).format("MMM"),
                padding: 10,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                // Include a dollar sign in the ticks
                callback: (value) => this.formatNumeral(value, "0,0 a"),
                /* 
                    ','' tanda ribuan 
                    '.' tanda desimal, 
                    angka 0 menunjukan kebutuhan banyaknya angka yang harus dipenuhi
                    contoh jika '000,0' berarti harus ada angka dengan 100,000 artinya membutuhkan
                    3 angka depan.
                    - jika '0,0' akan bernilai benar jika ada 1 atau lebih yang sama
                     */
                padding: 10,
              },
            },
          ],
        },
      };
    },
    dateNow() {
      return moment().format('DD MMM YYYY')
    },
  },
};
</script>

<style lang="scss" scoped>
.container-chart {
  width: 100%;

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    div.utama {
      gap: 5px;

      h1 {
        font-weight: bold;
        font-size: 18px;
        line-height: 140%;
        color: black;

        span {
          font-weight: normal;
          font-size: 14px;
          line-height: 150%;
          color: #6b7280;
        }
      }

      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        color: #6b7280;
      }
    }

    div.addition {
      gap: 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      div {
        height: 2px;
        width: 50px;
        background-color: black;
      }

      p {
        font-weight: 500;
        text-align: right;
        font-size: 12px;
        line-height: 20px;
        color: #6b7280;
        margin: 0;
      }
    }
  }

  canvas {
    width: 100%;
    margin-top: 39px;
    height: 350px;
  }
}

.compare-container {
  background-color: #f3f4f6;

  /* width: 100%; */
  // @media screen and (min-width: $sm) {
  //   & {
  //     background-color: red;
  //   }
  // }
  .compare-container-content {
    .content-1 {
      padding: $marginTopForContent 16px 50px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 40px 0;

      .heading {
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        color: #111827;
        margin: 0;
      }

      .container-table-parent {
        width: 100%;
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        gap: 10px;
        overflow-x: auto;

        @mixin background() {
          width: 100%;
          background-color: #f9fafb;
          border: 1px solid rgb(229 231 235);
          border-radius: 8px;
          box-shadow: 0px 1px 2px rgba(107, 114, 128, 0.06),
            0px 1px 3px rgba(107, 114, 128, 0.15);
        }

        .message {
          @include background;
          padding: 16px;
          display: flex;
          justify-content: center;

          h1 {
            font-size: 24px;
          }
        }

        .container-table,
        &::v-deep .table-responsive {
          @include background;

          // &::v-deep table tbody tr {
          //   background-color: red;
          // }
          table thead tr th {
            background-color: #f9fafb;
            color: rgb(75 85 99);
            font-weight: bold;
            font-size: 14px;
            line-height: 150%;
            text-align: start;
            padding: 12px 24px;
            white-space: nowrap;
          }

          // Kaya gini jg bisa
          table {
            tbody tr {
              td:first-child div {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 0 16px;

                div:first-child {
                  position: relative;
                  z-index: 1;

                  input[type="checkbox"] {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    opacity: 1;
                  }

                  input[type="checkbox"]:checked {
                    opacity: 0;
                    z-index: 2;
                  }

                  div {
                    width: 16px;
                    height: 16px;
                    z-index: -1;
                  }

                  input[type="checkbox"]:checked~div {
                    background-image: url("./../../assets/icons/ic-checked.png");
                    z-index: 1;
                  }
                }

                div.logo {
                  width: 40px;
                  height: 40px;
                  background-color: #3f0975;
                  border-radius: 6px;
                  flex-shrink: 0;
                  color: white;
                  font-size: 16px;
                  line-height: 150%;
                  display: flex;
                }

                div:last-child {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: center;

                  p {
                    color: black;
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                  }
                }
              }

              td {
                padding: 16px 24px;
                vertical-align: middle;

                p.textCommon {
                  color: black;
                  font-family: Inter;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 150%;
                }

                button.tombolBeli {
                  padding: 4px 8px;
                  border-radius: 4px;
                  background-color: #0b318f;
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 150%;
                  text-align: center;
                  color: white;
                  width: 66px;
                  border: 0;
                  outline: 0;
                }

                div.barometer {
                  gap: 0 10px;

                  div {
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: #0b318f;
                  }

                  div:last-child {
                    background-color: #d9d9d9;
                  }
                }

                p.navUnit {
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 150%;
                  color: rgb(17 24 39);
                  text-align: right;
                  margin: 0;
                }

                div.textPercent {
                  gap: 0 4px;

                  div {
                    flex-shrink: 0;
                    width: 20%;
                    height: 8px;
                  }

                  div.up {
                    background: rgb(22 163 74);
                    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                  }

                  div.down {
                    background: rgb(220 38 38);
                    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
                  }

                  p {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 150%;
                    color: rgb(22 163 74);
                    margin: 0;
                  }

                  div.down~p {
                    color: rgb(220 38 38) !important;
                  }
                }
              }
            }
          }
        }
      }

      .container-button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 10px;

        @media screen and (min-width: $lg) {
          & {
            flex-direction: row;
          }
        }

        @media screen and (min-width: $xl) {
          & {
            button:first-child {
              width: 25.72% !important;
            }

            button:last-child {
              width: max-content !important;
            }
          }
        }

        button {
          width: 100%;
          padding: 12px 16px;
          border-radius: 5px;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: white;
          border: 0;
          outline: 0;
        }

        button:first-child {
          background-color: #ff3f4e;
        }

        button:last-child {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #0b318f;
          width: 100%;

          div {
            margin-right: 5px;
            width: 1em;
            height: 1em;
            background-size: cover;
            background-image: url("../../assets/icons/ic_baseline-sim-card-download.svg");
          }
        }
      }

      .container-filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-direction: row;
        gap: 20px;
        flex-direction: column;

        @media screen and (min-width: $md) {
          & {
            align-items: flex-start;
          }
        }

        @media screen and (min-width: $xl) {
          & {
            flex-direction: row;
          }
        }

        .left {
          display: flex;
          justify-content: center;
          width: 100%;
          align-items: flex-start;
          gap: 20px;
          flex-direction: column;

          @media screen and (min-width: $lg) {
            & {
              flex-direction: row;
              align-items: center;

              p:first-child {
                white-space: nowrap;
              }
            }
          }

          @media screen and (min-width: $xl) {
            & {
              width: max-content;
              gap: 0px 16px;
            }
          }

          p:first-child {
            // white-space: nowrap;
            // flex-shrink: 0;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: black;
            margin: 0;
          }

          div {
            display: flex;
            align-items: center;
            width: 100%;
            flex-direction: column;
            gap: 10px;

            @media screen and (min-width: $sm) {
              & {
                gap: 0 5px;
                flex-direction: row;
              }
            }

            @media screen and (min-width: $xl) {
              & button {
                padding: 10px 12px !important;
                // padding: 10px 16px!important; menurut design yang ini
              }
            }

            button {
              padding: 10px 16px;
              border-radius: 4px;
              font-weight: normal;
              font-size: 16px;
              line-height: 150%;
              text-align: center;
              background-color: white;
              width: inherit;
              border: 0;
              outline: 0;
            }

            button.active {
              background-color: #d1deff;
            }
          }
        }

        .right {
          display: flex;
          justify-content: center;
          width: 100%;
          gap: 16px;
          flex-direction: column;
          align-items: flex-start;

          @media screen and (min-width: $md) {
            & {
              gap: 0 16px;
              flex-direction: row;
              width: max-content;
              align-items: center;

              input,
              button {
                width: initial !important;
              }
            }
          }

          label {
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            color: black;
            // flex-shrink: 0;
            margin: 0;
          }

          input {
            padding: 10px 16px;
            border-radius: 4px;
            border: 0;
          }

          button {
            padding: 10px 16px;
            border-radius: 4px;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            background-color: #d1deff;
            border: 0;
          }

          input,
          button {
            width: 100%;
          }
        }
      }
    }
  }

  // Tabs
  .tabsActive {
    font-weight: bold;
  }

}

.compare-container::v-deep .tabs-container {
  @media screen and (min-width: $xl2) {
    & {
      width: 1380px !important;
      max-width: 1380px !important;
    }
  }


}

.itemsTab {
  background: transparent;
}

.itemsTab::v-deep .active {
  background: transparent !important;
}

.content-2 {
  width: 100%;
  padding: 20px 16px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px 0;

  .heading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    p {
      margin: 0;
      font-weight: bold;
      font-size: 20px;
      line-height: 150%;
      color: rgb(17 24 39);
    }
  }

  .content-2-content {
    width: 100%;
    display: grid;
    gap: 20px;

    @media screen and (min-width: $xl) {
      & {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .nav-item {
      width: 100%;
      overflow: hidden;

      .nav-item-content {
        background: white;
        // padding: 24px 36px;
        padding: 16px;
        gap: 18px 0;
        box-shadow: 0px 1px 2px rgba(107, 114, 128, 0.06),
          0px 1px 3px rgba(107, 114, 128, 0.15);
        border-radius: 6px;
        width: 100%;
        overflow: auto;

        .content-loading {
          width: 100%;
          height: 300px;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            width: 100px;
            height: 100px;
            color: #ff3f4e;
          }

          p {
            margin: 0;
            font-size: 24px;
          }
        }

        .content {
          width: 100%;
          gap: 20px;

          div {
            width: 100%;
            height: 100%;
          }
        }

        .action {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f3f4f6;
          border: 1px solid #d1d5db;
          border-radius: 6px;
          flex-direction: column;
          overflow: hidden;

          @media screen and (min-width: $sm) {
            & {
              gap: 0 12px;
              flex-direction: row;
            }
          }

          button {
            padding: 7px 13px;
            font-weight: bold;
            font-size: 9px;
            line-height: 11px;
            text-align: center;
            color: #6b7280;
            border: 0;
            width: 100%;
          }

          button:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }

          button.active {
            background-color: #ff3f4e;
            color: white;
          }
        }
      }
    }
  }
}
</style>
